import React from 'react'
import { NextUIProvider } from '@nextui-org/react'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import {
  connectorsForWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  trustWallet,
  imTokenWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { NotificationsProvider } from 'reapop'
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { LanguageProvider } from 'contexts/I18nContext'
import { CHAINS } from 'config/constants/networks'
import getNodeUrl from 'utils/getRpcUrl'
import store from 'state'

const { chains, provider } = configureChains(
  CHAINS,
  [
    infuraProvider({
      apiKey: process.env.REACT_APP_INFURA_KEY,
      priority: 1,
      stallTimeout: 10_000,
    }),
    jsonRpcProvider({
      rpc: (chain) => {
        return {
          http: getNodeUrl(chain.id),
        }
      },
      priority: 0,
      stallTimeout: 10_000,
    }),
    publicProvider({ priority: 2, stallTimeout: 10_000 })
  ],
  {
    stallTimeout: 5_000,
    pollingInterval: 10_000,
  }
)

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID
const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      imTokenWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
    ],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const queryClient = new QueryClient()

const Providers: React.FC<any> = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <NotificationsProvider>
            <HelmetProvider>
              <LanguageProvider>
                <NextUIProvider>
                  <RainbowKitProvider
                    modalSize="compact"
                    chains={chains}
                    appInfo={{
                      learnMoreUrl: 'https://ethereum.org/en/web3/'
                    }}
                  >
                    <RefreshContextProvider>
                      {children}
                    </RefreshContextProvider>
                  </RainbowKitProvider>
                </NextUIProvider>
              </LanguageProvider>
            </HelmetProvider>
          </NotificationsProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiConfig>
  )
}

export default Providers