import React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl'
import {
  Grid,
  Container
} from '@nextui-org/react'
import {
  Button,
  Flex,
  Space
} from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'

const StyledButton = styled(Button) <any>`
  background-color: white;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  border: 2px solid #000;
  min-height: 46px;
  width: 100%;
  max-width: 74px;
  border-radius: 26px;
  padding: 10px 20px;
  font-size: 36px;
  font-weight: 600;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
  color: black;
  path {
    fill: black;
    color: black;
  }
  &:hover {
    background-color: #1e1e1e;
    color: white;
    path {
      color: white;
      fill: white;
    
    }
  }
`

const FooterWrapper = styled.div`
  background: var(--mainColor);
  min-height: 230px;
`

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FooterWrapper>
      <Container>
        <Flex direction="column" align="center" wrap="wrap">
          <Grid.Container gap={2} justify="flex-start">
            <Grid xs={12} sm={12} md lg justify='center'>
              <Flex direction="column" align="center" wrap="wrap">
                <StyledText size="25px">
                  <img src='/images/copyright-icon.svg' alt='x-icon' /> {process.env.REACT_APP_PROJECT_NAME} {currentYear}
                </StyledText>
                <Space h="xs" />
                {/* <a href={process.env.REACT_APP_TWITTER_LINK} target='_blank' rel='noreferrer noopener'>
                  <img src='/images/x-social-icon.svg' alt='x-icon' />
                </a> */}
                <StyledButton onClick={() => window.open(process.env.REACT_APP_TWITTER_LINK, '_blank')}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.901 1.15295H22.581L14.541 10.343L24 22.846H16.594L10.794 15.262L4.156 22.846H0.474L9.074 13.016L0 1.15395H7.594L12.837 8.08595L18.901 1.15295ZM17.61 20.644H19.649L6.486 3.23995H4.298L17.61 20.644Z" />
                  </svg>
                </StyledButton>
              </Flex>
            </Grid>
            <Grid xs={12} sm={12} md={9} lg={9} justify='flex-start'>
              <StyledText size="20px">
                <FormattedMessage
                  id="footerText"
                  defaultMessage="$red was made as an experiment and has no commercial value. The creator is lazy, main hobby is sleeping. Hence, the ecosystem will be fully run by AI content creators, the rebase process is automated and token price is fully community driven. Don’t risk money you are afraid of losing. Chill and good night!"
                />
              </StyledText>
            </Grid>
          </Grid.Container>
          <Flex direction="row" align="center" wrap="wrap">
            <img src='/images/powerby.png' alt='x-icon' width="200px" />
          </Flex>
        </Flex>
      </Container>
    </FooterWrapper >
  )
}

export default Footer