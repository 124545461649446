import React, { forwardRef } from 'react'
import {
  Text,
  Button
} from '@nextui-org/react'
import { templateId } from 'utils'
import { TextProps, createPolymorphicComponent, Text as MText } from '@mantine/core';

type TextTransforms =
  /* Keyword values */
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "full-width"
  | "full-size-kana"
  /* Global values */
  | "inherit"
  | "initial"
  | "revert"
  | "unset";

type TextWeights =
  | 'hairline'  // 100,
  | 'thin'  // 200,
  | 'light'  // 300,
  | 'normal'  // 400,
  | 'medium'  // 500,
  | 'semibold'  // 600,
  | 'bold'  // 700,
  | 'extrabold'  // 800,
  | 'black';  // 900


export const GeneralText: React.FC<{
  size: string | number
  color: string
  mt?: string
  transform?: TextTransforms
  weight: TextWeights
  cusColor?: string
  wordB?: string
  textAlign?: string
  children: React.ReactNode
}> = ({ size, color, mt, transform, weight, cusColor, wordB, textAlign, children }) => (
  <Text size={size} color={color} transform={transform} weight={weight} css={{
    marginTop: mt,
    color: cusColor,
    wordBreak: wordB,
    fontFamily: 'var(--fontFamily)',
    textAlign
  }}>
    {children}
  </Text>
)

export const ButtonStyled: React.FC<{
  width?: string
  height?: string
  borderRadius?: string
  isLoading?: boolean
  onClick?: any
  disabled?: boolean
  fSize?: string
  children: React.ReactNode
}> = ({ width, height, borderRadius, isLoading, onClick, disabled, fSize, children }) => (
  <Button
    rounded={templateId === '1' || templateId === '3'}
    css={{
      background: templateId === '1' ? '#DC70FA' : '#3772FF',
      width,
      height,
      borderRadius,
      fontSize: fSize
    }}
    iconRight={isLoading ? <i className="pi pi-spin pi-sync" style={{ fontSize: '1rem' }} /> : null}
    onPress={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
)

// Text
const _StyledText = forwardRef<HTMLButtonElement, { children: React.ReactNode, other }>(({ children, ...other }) => (
  <MText size='16px' color='var(--whiteColor)' fw={400} ff='var(--fontFamily)' lh={1.5} {...other}>
    {children}
  </MText>
))
export const StyledText = createPolymorphicComponent<'button', TextProps>(_StyledText)