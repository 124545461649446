import React, { useRef } from 'react'
import { Navbar } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import { Button, Flex } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import styled from '@emotion/styled'

const StyledButton = styled(Button) <any>`
  background-color: white;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  border: 2px solid #000;
  min-height: 46px;
  width: 100%;
  max-width: 74px;
  border-radius: 26px;
  padding: 10px 20px;
  font-size: 36px;
  font-weight: 600;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 1);
  color: black;
  path {
    fill: black;
    color: black;
  }
  &:hover {
    background-color: #1e1e1e;
    color: white;
    path {
      color: white;
      fill: white;
    
    }
  }
`

const MenuV2: React.FC = () => {
  const matchesMobile = useMediaQuery('(max-width: 499px)')

  const navbarToggleRef = useRef(null)

  const closeMenu = () => {
    navbarToggleRef?.current?.click()
  }

  return (
    <>
      <Navbar maxWidth='fluid' disableBlur disableShadow variant='static' className='cs-navbar' height={108} >
        <Navbar.Content gap='clamp(2rem, 7vw, 6rem)' hideIn='sm' css={{ marginLeft: '1rem', marginRight: 'auto' }}>
          <Link to={{ hash: '#feed' }}>
            <StyledText size='clamp(20px, 3vw, 25px)'>
              FEED
            </StyledText>
          </Link>
          <Link to={{ hash: '#token' }}>
            <StyledText size='clamp(20px, 3vw, 25px)'>
              TOKENOMICS
            </StyledText>
          </Link>
          <Link to={{ hash: '#buy' }}>
            <StyledText size='clamp(20px, 3vw, 25px)'>
              BUY
            </StyledText>
          </Link>
        </Navbar.Content>
        <Navbar.Content>
          <>
            {!matchesMobile && <Flex>
              <StyledButton onClick={() => window.open(process.env.REACT_APP_TWITTER_LINK, '_blank')}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.901 1.15295H22.581L14.541 10.343L24 22.846H16.594L10.794 15.262L4.156 22.846H0.474L9.074 13.016L0 1.15395H7.594L12.837 8.08595L18.901 1.15295ZM17.61 20.644H19.649L6.486 3.23995H4.298L17.61 20.644Z" />
                </svg>
              </StyledButton>
            </Flex>}
          </>
          <Navbar.Toggle showIn='sm' aria-label="toggle navigation" ref={navbarToggleRef} />
        </Navbar.Content>
        <Navbar.Collapse>
          <Navbar.CollapseItem onClick={() => closeMenu()}>
            <Link to={{ hash: '#feed' }}>
              <StyledText size='clamp(20px, 3vw, 25px)' color='var(--blackColor)'>
                FEED
              </StyledText>
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem onClick={() => closeMenu()}>
            <Link to={{ hash: '#token' }}>
              <StyledText size='clamp(20px, 3vw, 25px)' color='var(--blackColor)'>
                TOKENOMICS
              </StyledText>
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem onClick={() => closeMenu()}>
            <Link to={{ hash: '#buy' }}>
              <StyledText size='clamp(20px, 3vw, 25px)' color='var(--blackColor)'>
                BUY
              </StyledText>
            </Link>
          </Navbar.CollapseItem>
          {matchesMobile && <Navbar.CollapseItem>
            <StyledButton onClick={() => window.open(process.env.REACT_APP_TWITTER_LINK, '_blank')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.901 1.15295H22.581L14.541 10.343L24 22.846H16.594L10.794 15.262L4.156 22.846H0.474L9.074 13.016L0 1.15395H7.594L12.837 8.08595L18.901 1.15295ZM17.61 20.644H19.649L6.486 3.23995H4.298L17.61 20.644Z" />
              </svg>
            </StyledButton>
          </Navbar.CollapseItem>}
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default MenuV2