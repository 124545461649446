import React from 'react'
import styled from '@emotion/styled'
import Page from 'components/Layout/Page'
import { Loading } from '@nextui-org/react'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <Loading
        loadingCss={{ $$loadingSize: "100px", $$loadingBorder: "10px" }}
      />
    </Wrapper>
  )
}

export default PageLoader