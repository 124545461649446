import React, { lazy, useLayoutEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import MenuV2 from 'components/MenuV2'
import Footer from 'components/Footer'
import PageLoader from 'components/Loader/PageLoader'
import { usePollBlockNumber } from 'state/block/hooks'
import { useUpdateNetwork } from 'state/network/hooks'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import { templateId } from 'utils'

const NotFound = lazy(() => import('./views/NotFound'))
const Home = lazy(() => import('./views/Home'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return <Navigate replace {...rest} to={{
      pathname: location.pathname.replace(/\/+$/, ""),
      search: location.search
    }} />
  }

  return null
}

const App: React.FC = () => {
  usePollBlockNumber()
  useUpdateNetwork()

  // dynamic import css based on template
  useLayoutEffect(() => {
    async function importLibrary() {
      await import(`./css/Temp${templateId}.css`)
    }
    importLibrary()
  }, [])

  return (
    <BrowserRouter>
      <div className="master-container">
        <MenuV2 />
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <RemoveTrailingSlash />
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route path="/home">
              <Route index element={<Home />} />
            </Route>

            {/* 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SuspenseWithChunkError>
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default React.memo(App)
