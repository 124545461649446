import {
  avalanche,
  avalancheFuji
} from 'wagmi/chains'

// Network chain ids
export enum CHAIN_ID {
  AVALANCHE = 43114,
  FUJI = 43113,
}

export const defaultChainId = CHAIN_ID.AVALANCHE

// Network labels
export const NETWORK_LABEL = {
  [CHAIN_ID.AVALANCHE]: 'Avalanche',
  [CHAIN_ID.FUJI]: 'Fuji',
}

// Network RPC nodes
export const NETWORK_RPC = {
  [CHAIN_ID.AVALANCHE]: [
    'https://avalanche.drpc.org',
    'https://api.avax.network/ext/bc/C/rpc'
  ],
  [CHAIN_ID.FUJI]: ['https://api.avax-test.network/ext/bc/C/rpc'],
}

// Network block explorers
export const EXPLORER_URLS = {
  [CHAIN_ID.AVALANCHE]: 'https://snowtrace.io/',
  [CHAIN_ID.FUJI]: 'https://testnet.snowtrace.io/',
}

export const CHAINS = [
  avalanche,
  avalancheFuji
]